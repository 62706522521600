import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const HomeSliderContent = props => {
  return (
    <div className="main-slider_container">
      <a href={props.postLink}>
        <GatsbyImage
          style={{ height: "100%",width:"100%" }}
          image={props.imageSrc}
          alt={props.title}
        />
        {/* <img className="slider-image" src={props.imageSrc} /> */}
        <div className="main-slider_title">
          <span className="main-slider_number">{props.number}</span>
          <span className="main-slider_subtitle"> {props.title}</span>
        </div>
      </a>
    </div>
  )
}

export default HomeSliderContent
