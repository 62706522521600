import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Mainslider from "../components/Sections/HomePage"
import Seo from "../components/seo"

const Index = ({data}) => {
    return(
        <Layout isHomePage>
          <Seo title="Home"/>
            <div id='main-content'>
                <Mainslider sliderData={data}/>
            </div>
        </Layout>
    )
}

export const query = graphql`
   
{
    allWpPost(
    filter: {tags: {nodes: {elemMatch: {name: {eq: "Main_slider_Post"}}}}}
  ) {
    nodes {
      title
      id
      link
      custom_field_feature_images {
        sliderOrder
      }
      featuredImage {
        node {
          mediaItemUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}
`

export default Index;