import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Mousewheel} from "swiper"
import HomeSliderContent from "../Sliders/Contenthomeslider"

import "swiper/css"

const Mainslider = ({sliderData}) => {
  SwiperCore.use([Mousewheel])
  const sortedItems = sliderData.allWpPost.nodes.sort(function(a, b) {
    const nameA = a.custom_field_feature_images.sliderOrder;
    const nameB = b.custom_field_feature_images.sliderOrder; 
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
  
    // names must be equal
    return 0;
  })
  const SliderItems = sortedItems.map(( mainSliderPost, index) => {
    return(
      <SwiperSlide key={mainSliderPost.id} order={mainSliderPost.custom_field_feature_images.sliderOrder}>
          <HomeSliderContent
            postLink={mainSliderPost.link}
            title={mainSliderPost.title} 
            number={`${index+1}.`}
            imageSrc={mainSliderPost.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
          />
        </SwiperSlide>
    )
  })

  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={45}
        direction= {'vertical'}
        mousewheel={true}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          900: { slidesPerView: 1.2,
            direction: "horizontal",
            spaceBetween: 120,
            speed:1000
          },
          1300: { slidesPerView: 1.3,
              direction: "horizontal",
              spaceBetween: 120,
              speed:1000
          }
        }}
        className="mySwiper"
      >
        {SliderItems}
      </Swiper>
    </>
  )
}



export default Mainslider
